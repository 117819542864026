<template>
  <div class="flex flex-col space-y-4">
    <ProfileCard
      v-for="(d, index) in availableProfiles"
      :key="index"
      :dependent="d"
      :selected="targetGiftId === d.id"
      :unavailable="usedDependentGifts[d.id!]"
      :class="[
        'border-grays-light border-b pb-4',
        index === availableProfiles.length - 1 ? 'border-b-0' : '',
        usedDependentGifts[d.id!] ? 'cursor-not-allowed' : 'cursor-pointer',
      ]"
      @click="!usedDependentGifts[d.id!] && (targetGiftId = d.id!)"
    />
  </div>
</template>

<script setup lang="ts">
import type { CampaignGiftGetResponseBody } from '@/types/redemption'
import { ref, computed } from '#imports'
import { useGuestStore } from '~/store/guest'

const { dependents, gift, currentGiftId, calculateGiftAmt } = useGuestStore()

const availableProfiles = computed<CampaignGiftGetResponseBody[]>(() =>
  (gift ? [gift] : []).concat(dependents ?? []),
)

const usedDependentGifts = computed(() =>
  availableProfiles.value.reduce(
    (acc, profile) => {
      acc[profile.id!] =
        calculateGiftAmt(profile) === 0 && profile.email !== gift?.email
      return acc
    },
    {} as Record<string, boolean>,
  ),
)

const targetGiftId = ref<string>(currentGiftId)
const targetGift = computed<CampaignGiftGetResponseBody | undefined>(() =>
  availableProfiles.value.find(
    (g: CampaignGiftGetResponseBody) => g.id === targetGiftId.value,
  ),
)

defineExpose({ targetGiftId, targetGift })
</script>
