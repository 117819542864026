<template>
  <ul class="text-grays-darkest list-disc space-y-4 text-lg">
    <li>
      Credit is automatically applied to your account and works like a gift card
    </li>
    <li>
      Credit can be applied to all products from our current catalog, including
      taxes and shipping.
    </li>
    <li>You're responsible for any costs over your credit amount.</li>
    <li>Dependent profiles will be locked when they have no credit left.</li>
    <li>
      Credit must be used before your benefit period ends and does not carry
      over or transfer.
    </li>
  </ul>
</template>
