<template>
  <div v-if="props.target" class="flex flex-col space-y-6">
    <span class="text-grays-darkest text-lg">
      <span v-if="diffCatalogs">
        You have items in your cart. To switch profiles, you need to clear your
        cart. Would you like to proceed?
      </span>
      <span v-else>
        You have items in your cart. Would you like to keep them and purchase
        with
        <span class="font-bold"
          >{{
            `${target.recipient?.firstName} ${target.recipient?.lastName}`
          }}’s</span
        >
        profile, or remove them from your cart?
      </span>
    </span>
    <template v-if="diffCatalogs">
      <ZnButton type="primary" class="w-full text-center" @click="emit('clear')"
        >Clear cart and switch</ZnButton
      >
      <ZnButton
        type="secondary"
        class="w-full text-center"
        @click="emit('cancel')"
        >Cancel</ZnButton
      >
    </template>
    <template v-else>
      <ZnButton type="primary" class="w-full text-center" @click="emit('keep')"
        >Keep items</ZnButton
      >
      <ZnButton
        type="secondary"
        class="w-full text-center"
        @click="emit('clear')"
        >Clear cart</ZnButton
      >
    </template>
  </div>
</template>

<script setup lang="ts">
import type { CampaignGiftGetResponseBody } from '@/types/redemption'
import { computed } from '#imports'
import { useGuestStore } from '~/store/guest'
import type { PropType } from 'vue'

const emit = defineEmits(['keep', 'clear', 'cancel'])

const { currentGift } = useGuestStore()

const props = defineProps({
  target: {
    type: Object as PropType<CampaignGiftGetResponseBody>,
    required: true,
    default: () => {},
  },
})

const diffCatalogs = computed<boolean>(
  () =>
    props.target?.policy?.catalogs?.some(
      (catalog) => !currentGift?.policy?.catalogs?.includes(catalog),
    ) ?? false,
)
</script>
